import { t, Trans } from '@lingui/macro';
import { concat } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/Button/Button';
import ExpandButton from '../../../components/ExpandButton/ExpandButton';
import Input from '../../../components/Input/Input';
import InputLabel from '../../../components/InputLabel';
import Select from '../../../components/Select/Select';
import useAnalytics from '../../../hooks/useAnalytics';
import useRole from '../../../hooks/useRole';
import useUser from '../../../hooks/useUser';
import { deriveCustomerOptions } from '../../../services/Customer';
import convertToSearchDate from '../../../services/Util/convertToSearchDate.util';
import { AuthorityRole } from '../../../types/Authority.interface';
import Option from '../../../types/Option.type';
import { SearchOrderInputs } from '../SearchOrderInputs.interface';
import AdvancedSearchCriteria from './AdvancedSearchCriteria';
import OrderRelatedDates from './OrderRelatedDates';
import { getAllTypes } from './SearchCriteria.util';
import useTrackOrdersStore from '../useTrackOrders.store';
import ControlledSelectMulti, { AntdSelectOption } from '../../../components/ControlledSelectMulti/ControlledSelectMulti';
import { getUnfilteredStatusOptions } from '../ViewOrders/ViewOrdersSearchForm.util';

interface ISearchCriteriaProps {
  onApplyFilters: (filters: SearchOrderInputs) => void;
  onReset: () => void;
  filters: Partial<SearchOrderInputs>;
}

const SearchCriteria: React.FunctionComponent<ISearchCriteriaProps> = ({
  onApplyFilters,
  onReset,
  filters,
}) => {
  const { hasRole, isNonReleaseEuUser } = useRole();
  const { storedQuery: storedFilters } = useTrackOrdersStore();
  const { register, handleSubmit, reset, errors, watch, setValue, control, getValues } = useForm({
    mode: 'all',
  });
  const [minOrderDate, setMinOrderDate] = useState<string>();
  const [maxOrderDate, setMaxOrderDate] = useState<string>();
  const [minLastRevisionDate, setMinLastRevisionDate] = useState<string>();
  const [maxLastRevisionDate, setMaxLastRevisionDate] = useState<string>();
  const [minDeliveryDate, setMinDeliveryDate] = useState<string>();
  const [maxDeliveryDate, setMaxDeliveryDate] = useState<string>();
  const [minArrivalDate, setMinArrivalDate] = useState<string>();
  const [maxArrivalDate, setMaxArrivalDate] = useState<string>();
  const [monthChosen, setMonthChosen] = useState<number>(0);

  const { trackPageView } = useAnalytics();
  const { data: user } = useUser();
  const [customerOptions, setCustomerOptions] = useState<Option[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<string>(
    storedFilters?.customerNumber || ''
  );
  const [advanceSearchExpanded, setAdvanceSearchExpanded] = useState(false);

  const [validated, setValidated] = useState<boolean>(true);

  const [resetOrderRelatedDates, setResetOrderRelatedDates] = useState<boolean>(
    false
  );

  const submit = handleSubmit((data: SearchOrderInputs) => {
    data.minOrderDate = minOrderDate
      ? convertToSearchDate(
          minOrderDate,
          monthChosen > 0 ? true : hasRole(AuthorityRole.ROLE_NA)
        )
      : '';

    data.maxOrderDate = maxOrderDate
      ? convertToSearchDate(
          maxOrderDate,
          monthChosen > 0 ? true : hasRole(AuthorityRole.ROLE_NA)
        )
      : '';

    data.minLastRevisionDate = minLastRevisionDate
      ? convertToSearchDate(minLastRevisionDate, hasRole(AuthorityRole.ROLE_NA))
      : '';

    data.maxLastRevisionDate = maxLastRevisionDate
      ? convertToSearchDate(maxLastRevisionDate, hasRole(AuthorityRole.ROLE_NA))
      : '';

    data.minDeliveryDate = minDeliveryDate
      ? convertToSearchDate(minDeliveryDate, hasRole(AuthorityRole.ROLE_NA))
      : '';

    data.maxDeliveryDate = maxDeliveryDate
      ? convertToSearchDate(maxDeliveryDate, hasRole(AuthorityRole.ROLE_NA))
      : '';

    data.minArrivalDate = minArrivalDate
      ? convertToSearchDate(minArrivalDate, hasRole(AuthorityRole.ROLE_NA))
      : '';

    data.maxArrivalDate = maxArrivalDate
      ? convertToSearchDate(maxArrivalDate, hasRole(AuthorityRole.ROLE_NA))
      : '';

    if (
      data.customerOrderNumber ||
      data.shipToPONumber ||
      data.documentNumber ||
      data.containerNumber ||
      data.invoiceNumber ||
      data.billOfLadingNumber ||
      data.weightListNumber ||
      data.minOrderDate ||
      data.maxOrderDate ||
      data.minLastRevisionDate ||
      data.maxLastRevisionDate ||
      data.minDeliveryDate ||
      data.maxDeliveryDate ||
      data.minArrivalDate ||
      data.maxArrivalDate
    ) {
      trackPageView('TRACK_ORDERS', 'SEARCH', {
        customerId: selectedCustomer || 'ALL ACCOUNT',
      });

      setValidated(true);

      onApplyFilters({
        ...data,
        customerNumber:
          selectedCustomer === '0' ? '' : String(selectedCustomer),
      });
    } else {
      setValidated(false);
    }
  });

  // All inputs that have error handling need to be reset in this manner as well
  const resetAdvancedSearch = () => {
    setValue('caliperMicrons', '');
    setValue('widthMinInches', '');
    setValue('widthMin', '');
    setValue('widthMaxInches', '');
    setValue('widthMax', '');
    setValue('lengthMin', '');
    setValue('lengthMinInches', '');
    setValue('lengthMaxInches', '');
    setValue('lengthMax', '');
  };

  const onSubmitReset = () => {
    resetAdvancedSearch();
    reset();
    setSelectedCustomer('');
    setValidated(true);
    onReset();

    setMinOrderDate('');
    setMaxOrderDate('');
    setMinLastRevisionDate('');
    setMaxLastRevisionDate('');
    setMinDeliveryDate('');
    setMaxDeliveryDate('');
    setMinArrivalDate('');
    setMaxArrivalDate('');

    setResetOrderRelatedDates((state) => !state);
  };

  function doSetSelectedCustomer(customer: string) {
    setSelectedCustomer(customer);
  }

  useEffect(() => {
    if (user) {
      const { customers } = user;
      const derivedCustomerOptions = deriveCustomerOptions(
        customers || [],
        isNonReleaseEuUser,
        hasRole(AuthorityRole.ROLE_EU),
        hasRole(AuthorityRole.ROLE_RELEASE)
      );
      setCustomerOptions(
        concat([{ label: t`ALL ACCOUNTS`, value: 0 }], derivedCustomerOptions)
      );
    }
  }, [user, isNonReleaseEuUser, hasRole]);

  useEffect(() => {
    setValue('statusCode', storedFilters?.statusCode);
    setValue('statusTexts', storedFilters?.statusTexts);
    setValue('customerOrderNumber', storedFilters?.customerOrderNumber);
    setValue('paperBrand', storedFilters?.paperBrand);
    setValue('documentNumber', storedFilters?.documentNumber);
    setValue('shipToPONumber', storedFilters?.shipToPONumber);
    setValue('containerNumber', storedFilters?.containerNumber);
    setValue('invoiceNumber', storedFilters?.invoiceNumber);
    setValue('weightListNumber', storedFilters?.weightListNumber);
    setValue('billOfLadingNumber', storedFilters?.billOfLadingNumber);
    if (storedFilters?.typeCode) {
      setValue('typeCode', storedFilters.typeCode);
    }
    setValue('kcode', storedFilters?.kcode);
    setValue('monthsFromToday', storedFilters?.monthsFromToday);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <>
      <form onSubmit={submit}>
        <div className="mx-5 py-5">
          <div className="my-5 flex border-b border-gray-300">
            <fieldset className="w-3/6 pr-1 align-top">
              <div className="py-2.5">
                <InputLabel
                  text={t`Customer`}
                  size="lg"
                  className="text-gray-dark200"
                />
                <Select
                  name="customer"
                  value={selectedCustomer}
                  options={customerOptions}
                  onChange={(e) => {
                    const customer = e.target.value;
                    doSetSelectedCustomer(customer);
                  }}
                />
              </div>
              <div className="block">
                <div className="w-3/6 my-5 inline-block">
                  <p>
                    <Trans>
                      Use an * as a wildcard to match one or more characters in
                      the Order # search.
                    </Trans>
                  </p>
                  {!(
                    hasRole(AuthorityRole.ROLE_SHIP_TO) ||
                    hasRole(AuthorityRole.ROLE_PROJECT_GROUP)
                  ) && (
                    <Input
                      name="customerOrderNumber"
                      className="mb-3.5 shadow-md text-blue-pacific"
                      type="text"
                      placeholder={t`My Order #`}
                      ref={register({ maxLength: 50 })}
                      defaultValue={filters.customerOrderNumber}
                    />
                  )}
                  {(hasRole(AuthorityRole.ROLE_SHIP_TO) ||
                    hasRole(AuthorityRole.ROLE_PROJECT_GROUP)) && (
                    <Input
                      name="shipToPONumber"
                      className="mb-3.5 shadow-md text-blue-pacific"
                      type="text"
                      placeholder={t`My Order #`}
                      ref={register({ maxLength: 50 })}
                    />
                  )}
                  <Input
                    name="documentNumber"
                    className="mb-3.5 shadow-md text-blue-pacific"
                    type="text"
                    placeholder={t`Sappi Order #`}
                    ref={register({ maxLength: 50 })}
                  />
                  <Input
                    name="containerNumber"
                    className="mb-3.5 shadow-md text-blue-pacific"
                    type="text"
                    placeholder={t`Container/Trailer #`}
                    ref={register({ maxLength: 50 })}
                  />
                  <div>
                    <Input
                      name="invoiceNumber"
                      className="mb-3.5 shadow-md text-blue-pacific"
                      type="text"
                      placeholder={t`Invoice #`}
                      ref={register({ maxLength: 50 })}
                    />
                  </div>
                  <div>
                    <InputLabel
                      text={t`Bill of Lading #`}
                      size="lg"
                      className="text-gray-dark200"
                    />
                    <Input
                      name="billOfLadingNumber"
                      className="mb-3.5 mr-6 p-1.5 w-4/6 h-8 shadow-md text-blue-pacific"
                      type="text"
                      placeholder={t`Bill of Lading #`}
                      ref={register({ maxLength: 50 })}
                    />
                  </div>
                  <div>
                    <InputLabel
                      text={t`Weight List #`}
                      size="lg"
                      className="text-gray-dark200"
                    />
                    <Input
                      name="weightListNumber"
                      className="mb-3.5 mr-6 p-1.5 w-4/6 h-8 shadow-md text-blue-pacific"
                      type="number"
                      placeholder={t`Weight List #`}
                      ref={register({ maxLength: 10 })}
                      error={errors.weightListNumber}
                    />
                  </div>
                </div>
                <div className="w-3/6 my-5 inline-block align-top">
                  <div className="mb-4">
                    <InputLabel
                      text={t`Status of Order`}
                      size="lg"
                      className="text-gray-dark200"
                    />
                    <p>
                      <Trans>Select Options (Multiple Choices Allowed)</Trans>
                    </p>
                    <ControlledSelectMulti
                      name="statusTexts"
                      control={control}
                      getValues={getValues}
                      defaultValue={filters.statusTexts || []}
                      className="w-full"
                      options={
                        getUnfilteredStatusOptions(
                          hasRole(AuthorityRole.ROLE_NA)
                        ) as AntdSelectOption[]
                      }
                      labelCapitalizeTransform="capitalize"
                      placeholder={t`All`}
                    />
                  </div>
                  <div>
                    <InputLabel
                      text={t`Type of Order`}
                      size="lg"
                      className="text-gray-dark200"
                    />
                    <Select
                      name="typeCode"
                      options={getAllTypes(
                        hasRole(AuthorityRole.ROLE_CONSIGNMENT),
                        hasRole(AuthorityRole.ROLE_RELEASE)
                      )}
                      ref={register}
                    />
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset className="w-3/6 pl-1 align-top">
              <OrderRelatedDates
                onMinOrderDateChange={setMinOrderDate}
                onMaxOrderDateChange={setMaxOrderDate}
                onMinLastRevisionDateChange={setMinLastRevisionDate}
                onMaxLastRevisionDateChange={setMaxLastRevisionDate}
                onMinDeliveryDateChange={setMinDeliveryDate}
                onMaxDeliveryDateChange={setMaxDeliveryDate}
                onMinArrivalDateChange={setMinArrivalDate}
                onMaxArrivalDateChange={setMaxArrivalDate}
                onMonthChosenChange={setMonthChosen}
                resetOrderRelatedDates={resetOrderRelatedDates}
                register={register}
              />
            </fieldset>
          </div>

          {customerOptions && customerOptions.length && (
            <AdvancedSearchCriteria
              selectedCustomer={
                selectedCustomer || (customerOptions[1].value as string)
              }
              expanded={advanceSearchExpanded}
              register={register}
              errors={errors}
              watch={watch}
              setValue={setValue}
            />
          )}

          <div className="flex items-center">
            <ExpandButton
              onClick={() => setAdvanceSearchExpanded(!advanceSearchExpanded)}
              isExpanded={advanceSearchExpanded}
            >
              <Trans>Advanced Search</Trans>
            </ExpandButton>
            <span className="px-2" />
            <Button id="searchButton" type="submit" theme="primary">
              <Trans>Search</Trans>
            </Button>
            <span className="px-2" />
            <Button
              type="reset"
              theme="link"
              className="text-lg font-bold"
              onClick={onSubmitReset}
            >
              <Trans>Reset</Trans>
            </Button>
            {!validated && (
              <>
                <span className="px-1" />
                <label className="inline-block px-5 leading-4 text-red-600 w-1/3 h-11">
                  To perform a search of submitted orders, please enter{' '}
                  <strong>at minimum</strong> a Document Number{' '}
                  <strong>or</strong> a Date Search Parameter.
                </label>
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default SearchCriteria;
