import { t } from '@lingui/macro';

export const CANNOT_REUSE_ERROR = t`New password invalid. Cannot reuse one of your last 5 passwords`;
export const MIN_CHARACTERS_ERROR = t`New password invalid. Must contain at least 8 characters`;
export const MAX_CHARACTERS_ERROR = t`New password invalid. Must not contain more than 14 characters`;
export const MIXED_CASE_ERROR = t`New password invalid. Include at least one lowercase and one uppercase character`;
export const ALPHA_NUMBER_ERROR = t`New password invalid. Include letters and at least one number`;
export const INVALID_OLD_PASSWORD = t`Your old password is invalid`;
export const DEFAULT_PASSWORD_ERROR = t`There was a problem updating your password`

export const getPasswordErrorMessage = (errorString: string) => {
  switch (errorString) {
    case 'password.error.toShort': {
      return MIN_CHARACTERS_ERROR;
    }
    case 'password.error.toLong': {
      return MAX_CHARACTERS_ERROR;
    }
    case 'password.error.mixed_case_required': {
      return MIXED_CASE_ERROR;
    }
    case 'password.error.invalidOldPassword': {
      return INVALID_OLD_PASSWORD;
    }
    case 'password.error.alpha_required': {
      return ALPHA_NUMBER_ERROR;
    }
    default: 
      return DEFAULT_PASSWORD_ERROR;
  }
}