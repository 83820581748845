import { t } from '@lingui/macro';
import { AuthorityRole } from '../../types/Authority.interface';
import { SappiMenuItem } from './Types/SappiMenuItem.interface';

// Roles are ANDed between elements in the main array and ORed between elements in the sub array. So ROLE_1 and either ROLE_A or ROLE_B would be: ['ROLE_1',['ROLE_A','ROLE_B']]
export const getAllLinks = (): SappiMenuItem[] => {
  return [
    {
      id: 'my_orders',
      name: t`My Orders`,
      rolesArray: [
        [
          AuthorityRole.ROLE_DIRECT_BOOKING,
          AuthorityRole.ROLE_ORDER_TRACKING,
          AuthorityRole.ROLE_MILL_DIRECT,
          AuthorityRole.ROLE_STOCK_AVAILABILITY,
          AuthorityRole.ROLE_DOCUMENT_PRINTER,
          AuthorityRole.ROLE_TRACK_BY,
          AuthorityRole.ROLE_LABEL_PRINTER,
          AuthorityRole.ROLE_RELEASE,
        ],
      ],
      children: [
        {
          id: 'order_booking',
          name: t`Order Booking`,
          url: '/orderBooking',
          target: '_self',
          roles: [AuthorityRole.ROLE_DIRECT_BOOKING, AuthorityRole.ROLE_EU],
        },
        {
          id: 'quick_order_booking',
          name: t`Quick Order`,
          url: '/quickOrder',
          target: '_self',
          roles: [AuthorityRole.ROLE_QOB],
        },
        {
          id: 'order_tracking',
          name: t`Track Orders`,
          url: '/orderTracking',
          target: '_self',
          roles: [AuthorityRole.ROLE_ORDER_TRACKING],
        },
        {
          id: 'file_claim',
          name: t`File a Claim`,
          url: '/fileClaimSearch',
          target: '_self',
          roles: [AuthorityRole.ROLE_COMPLAINT_CREATE],
        },
        {
          id: 'mill_direct',
          name: t`View All Open Orders`,
          url: '/millDirect/search',
          target: '_self',
          roles: [AuthorityRole.ROLE_MILL_DIRECT],
        },
        {
          id: 'print_documents',
          name: t`Print Documents`,
          url: '/documentPrinter_',
          target: '_self',
          roles: [AuthorityRole.ROLE_DOCUMENT_PRINTER],
        },
        {
          id: 'saved_enquiries',
          name: t`My Saved Enquiries`,
          url: '/savedenquiries',
          target: '_self',
          roles: [AuthorityRole.ROLE_EU, AuthorityRole.ROLE_DIRECT_BOOKING],
          rolesArray: [
            [
              AuthorityRole.ROLE_STOCK_AVAILABILITY,
              AuthorityRole.ROLE_DIRECT_BOOKING,
            ],
          ],
          excludedRoles: [AuthorityRole.ROLE_RELEASE],
        },
        {
          id: 'print_labels',
          name: t`Print Labels`,
          url: '/labelPrinter',
          target: '_self',
          roles: [AuthorityRole.ROLE_EU, AuthorityRole.ROLE_LABEL_PRINTER],
        },
        {
          id: 'track_by_label',
          name: t`Track By Label ID`,
          url: '/trackByLabel',
          target: '_self',
          roles: [AuthorityRole.ROLE_TRACK_BY],
        },
        {
          id: 'consignment_consumption',
          name: t`Consignment Consumption`,
          url: '/consignmentConsumption',
          target: '_self',
          roles: [AuthorityRole.ROLE_CONSIGN_SUBMIT],
        },
      ],
    },
    {
      id: 'availability',
      name: t`Availability`,
      rolesArray: [
        [
          AuthorityRole.ROLE_STOCK_AVAILABILITY,
          AuthorityRole.ROLE_PRODUCTION_PLANNING,
          AuthorityRole.ROLE_STOCK_LOT,
        ],
      ],
      children: [
        {
          id: 'availability_planning',
          name: t`Product Availability`,
          url: '/availabilityPlanning',
          target: '_self',
          roles: [AuthorityRole.ROLE_STOCK_AVAILABILITY],
        },
        /* {
          id: 'availability_planning_eu_rel',
          name: t`Product Availability`,
          url: '/availabilityPlanning',
          target: '_self',
          roles: [
            AuthorityRole.ROLE_EU,
            AuthorityRole.ROLE_STOCK_AVAILABILITY,
            AuthorityRole.ROLE_RELEASE,
          ],
        }, */
        {
          id: 'stock_book',
          name: t`Stock Book`,
          url: 'https://www.sappi.com/files/sappi-north-america-stock-bookpdf',
          target: '_blank',
          roles: [
            AuthorityRole.ROLE_NA,
            AuthorityRole.ROLE_STOCK_VIEW,
            AuthorityRole.ROLE_STOCK_REPORT,
          ],
          excludedRoles: [AuthorityRole.ROLE_RELEASE],
        },
        {
          id: 'stock_book_rel',
          name: t`Stock Book`,
          url: 'https://www.sappi.com/textures',
          target: '_blank',
          roles: [
            AuthorityRole.ROLE_STOCK_VIEW,
            AuthorityRole.ROLE_STOCK_REPORT,
            AuthorityRole.ROLE_RELEASE,
          ],
        },
        // TODO: Will be deleted till the sap work is done to enable metric values in NA prod availability
        // {
        //   id: 'stock_availability_eu',
        //   name: t`Stock Availability`,
        //   url: '/stockAvailability',
        //   target: '_self',
        //   roles: [AuthorityRole.ROLE_EU, AuthorityRole.ROLE_STOCK_AVAILABILITY],
        //   excludedRoles: [AuthorityRole.ROLE_RELEASE],
        // },
        // {
        //   id: 'product_availability_eu',
        //   name: t`Product Availability`,
        //   url: '/productAvailability',
        //   target: '_self',
        //   roles: [AuthorityRole.ROLE_EU, AuthorityRole.ROLE_STOCK_AVAILABILITY],
        //   excludedRoles: [AuthorityRole.ROLE_RELEASE],
        // },
        {
          id: 'stock_lot',
          name: t`Stock Lot`,
          url: '/stockLot',
          target: '_self',
          roles: [AuthorityRole.ROLE_EU, AuthorityRole.ROLE_STOCK_LOT],
        },
      ],
    },
    // no menu for Track And Trace User now
    /* {
      id: 'track_and_trace',
      name: t`Track And Trace`,
      roles: [AuthorityRole.ROLE_TRACK_AND_TRACE],
      children: [
        {
          id: 'track_and_trace',
          name: t`Track And Trace`,
          target: '_self',
        },
        {
          id: 'print_labels',
          name: t`Print Labels`,
          url: '/labelPrinter',
          target: '_self',
          roles: [AuthorityRole.ROLE_EU, AuthorityRole.ROLE_LABEL_PRINTER],
        },
      ],
    }, */
    {
      id: 'reporting',
      name: t`Reporting`,
      rolesArray: [
        [
          AuthorityRole.ROLE_COMPLAINT_REQUEST,
          AuthorityRole.ROLE_FINANCIAL_INFO,
          AuthorityRole.ROLE_CONSIGNMENT,
          AuthorityRole.ROLE_CONTAINER_LIST,
        ],
      ],
      children: [
        {
          id: 'financial_reports',
          name: t`Financial`,
          breadCrumb: t`Financial Reporting`,
          url: '/reporting/financialReport',
          target: '_self',
          roles: [AuthorityRole.ROLE_FINANCIAL_INFO],
        },
        {
          id: 'complaint_reports',
          name: t`Claims`,
          breadCrumb: t`Claims Reporting`,
          url: '/reporting/claimReport',
          target: '_self',
          roles: [AuthorityRole.ROLE_COMPLAINT_REQUEST],
        },
        {
          id: 'consignment',
          name: t`Consignment`,
          url: '/consignment',
          target: '_self',
          roles: [AuthorityRole.ROLE_CONSIGNMENT],
        },
        {
          id: 'packing_list',
          name: t`Packing List`,
          breadCrumb: t`Packing List Reporting`,
          url: '/packingList',
          target: '_self',
          roles: [AuthorityRole.ROLE_CONTAINER_LIST],
        },
      ],
    },
    {
      id: 'calculators',
      name: t`Calculators`,
      children: [
        {
          id: 'sheet_calculators',
          name: t`Sheet Calculators`,
          url: '/calculators?type=sheet',
          target: '_self',
          roles: [AuthorityRole.ROLE_NA],
          excludedRoles: [AuthorityRole.ROLE_RELEASE],
        },
        {
          id: 'roll_calculators',
          name: t`Roll Calculators`,
          url: '/calculators?type=roll',
          target: '_self',
          roles: [AuthorityRole.ROLE_NA],
        },
        {
          id: 'roll_calculators_release',
          name: t`Roll Calculators`,
          url: '/calculators?type=roll',
          target: '_self',
          roles: [AuthorityRole.ROLE_EU, AuthorityRole.ROLE_RELEASE],
        },
        {
          id: 'price_calculators',
          name: t`Price Calculators`,
          url: '/calculators?type=price',
          target: '_self',
          roles: [AuthorityRole.ROLE_NA],
        },
        {
          id: 'price_calculators_eu_rel',
          name: t`Price Calculators`,
          url: '/calculators?type=price',
          target: '_self',
          roles: [AuthorityRole.ROLE_EU, AuthorityRole.ROLE_RELEASE],
        },
        {
          id: 'converters_calculators',
          name: t`Conversion Calculators`,
          url: '/calculators?type=converters',
          target: '_self',
          roles: [AuthorityRole.ROLE_NA],
        },
        {
          id: 'converters_calculators_eu_rel',
          name: t`Conversion Calculators`,
          url: '/calculators?type=converters',
          target: '_self',
          roles: [AuthorityRole.ROLE_EU, AuthorityRole.ROLE_RELEASE],
        },
        {
          id: 'eq_tool_calculators',
          name: t`eQ Calculator`,
          url: '/calculators?type=eqtools',
          target: '_self',
          roles: [AuthorityRole.ROLE_NA],
          excludedRoles: [AuthorityRole.ROLE_RELEASE],
        },
        {
          id: 'length_conversion_calculator',
          name: t`Metric Conversions`,
          url: '/calculators?type=metric-conversions',
          target: '_self',
          roles: [AuthorityRole.ROLE_EU],
          excludedRoles: [AuthorityRole.ROLE_RELEASE],
        },
        {
          id: 'weight_conversion_calculator',
          name: t`Paper Math Calculator`,
          url: '/calculators?type=paper-math',
          target: '_self',
          roles: [AuthorityRole.ROLE_EU],
          excludedRoles: [AuthorityRole.ROLE_RELEASE],
        },
        {
          id: 'rolll_calculator',
          name: t`Roll Calculators`,
          url: '/calculators?type=reel',
          target: '_self',
          roles: [AuthorityRole.ROLE_EU],
          excludedRoles: [AuthorityRole.ROLE_RELEASE],
        },
        {
          id: 'pallet_calculator',
          name: t`Pallet Height & Weight Calculator`,
          url: '/calculators?type=pallet',
          target: '_self',
          roles: [AuthorityRole.ROLE_EU],
          excludedRoles: [AuthorityRole.ROLE_RELEASE],
        },
        {
          id: 'payload_calculator',
          name: t`Payload Calculator`,
          url: '/calculators?type=payload',
          target: '_self',
          roles: [AuthorityRole.ROLE_EU],
          excludedRoles: [AuthorityRole.ROLE_RELEASE],
        },
      ],
    },
    {
      id: 'samples',
      name: t`Samples`,
      roles: [AuthorityRole.ROLE_EU, AuthorityRole.ROLE_SAMPLES],
      excludedRoles: [AuthorityRole.ROLE_RELEASE],
      children: [
        {
          id: 'samples_create_order',
          name: t`Create a Sample Order`,
          breadCrumb: t`Order Samples`,
          url: '/samples/findProducts',
          target: '_self',
        },
        {
          id: 'samples_order_status',
          name: t`My Sample Orders`,
          url: '/samples/status',
          target: '_self',
        },
      ],
    },
    {
      id: 'company_info',
      name: t`Company Info`,
      children: [
        {
          id: 'contact_us',
          name: t`Contact Us`,
          url: 'https://www.sappi.com/contact-us',
          target: '_blank',
        },
        {
          id: 'about_us_na',
          name: t`About Us`,
          url: 'https://www.sappi.com/aboutus',
          target: '_blank',
          roles: [AuthorityRole.ROLE_NA],
        },
        {
          id: 'about_us_eu',
          name: t`About Us`,
          url: 'https://www.sappi.com/',
          target: '_blank',
          roles: [AuthorityRole.ROLE_EU],
        },
        {
          id: 'sustainability',
          name: t`Sustainability & Impact`,
          url: 'https://www.sappi.com/sustainability-and-impact',
          target: '_blank',
        },
        {
          id: 'sustainability_docs',
          name: t`Sustainability Documents`,
          url: 'https://www.sappi.com/sustainability-certifications',
          target: '_blank',
        },
        {
          id: 'press_releases',
          name: t`Press Releases & Announcements`,
          url: 'https://www.sappi.com/news-and-events-listing',
          target: '_blank',
        },
        {
          id: 'policies',
          name: t`Regulatory Documents`,
          url: '/policies',
          target: '_self',
        },
      ],
    },
  ];
};
