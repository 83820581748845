import { t, Trans } from '@lingui/macro';
import { upperCase } from 'lodash';
import React from 'react';
import { ColumnType } from 'antd/lib/table';
import { ViewOrdersRow } from './ViewOrdersRow.interface';
import OrderCellForWidgetTable from './OrderCellForWidgetTable';
import {
  confirmedDeliveryDateCell,
  productCell,
  sappiSkuMaterialNumCell,
} from './viewOrdersTableCell.util';
import { mapOldStatusTextToNew } from '../../OrderStatuses';

export const orderColumn: ColumnType<ViewOrdersRow> = {
  title: <Trans>Order</Trans>,
  dataIndex: 'order',
  key: 'order',
  render: (text: string, record: ViewOrdersRow) => (
    <OrderCellForWidgetTable record={record} />
  ),
  sorter: (a: ViewOrdersRow, b: ViewOrdersRow) =>
    a.customerOrderNumber > b.customerOrderNumber ? 1 : -1,
  sortDirections: ['descend', 'ascend'],
};

export const stateColumn: ColumnType<ViewOrdersRow> = {
  title: <Trans>Status</Trans>,
  dataIndex: 'status',
  key: 'status',
  render: (text: string, record: ViewOrdersRow) =>
    record.overviews[0].orderStatusText,
  sorter: (a: ViewOrdersRow, b: ViewOrdersRow) =>
    a.overviews[0].status > b.overviews[0].status ? 1 : -1,
  sortDirections: ['descend', 'ascend'],
};

export const stateTranslatedColumn = (
  isRoleNA: boolean
): ColumnType<ViewOrdersRow> => {
  return {
    ...stateColumn,
    render: (text: string, record: ViewOrdersRow) => {
      return (
        <div>
          {record.overviews && record.overviews.length === 1 && (
            <span>
              {upperCase(
                mapOldStatusTextToNew(
                  record.overviews[0].orderStatusText,
                  isRoleNA
                )
              )}
            </span>
          )}
        </div>
      );
    },
  };
};

export const productColumn: ColumnType<ViewOrdersRow> = {
  title: <Trans>Product</Trans>,
  dataIndex: 'product',
  key: 'product',
  render: productCell,
};

export const skuColumn: ColumnType<ViewOrdersRow> = {
  title: <Trans>Sappi SKU / Material #</Trans>,
  dataIndex: 'sku',
  key: 'sku',
  render: sappiSkuMaterialNumCell,
};

export const confirmedDeliveryDateColumn: ColumnType<ViewOrdersRow> = {
  title: <Trans>Confirmed Delivery Date</Trans>,
  dataIndex: 'confirmedDeliveryDate',
  key: 'confirmedDeliveryDate',
  render: confirmedDeliveryDateCell,
  sorter: (a: ViewOrdersRow, b: ViewOrdersRow) =>
    a.overviews[0].deliveryDate > b.overviews[0].deliveryDate ? 1 : -1,
  sortDirections: ['descend', 'ascend'],
};

export const shipToColumn: ColumnType<ViewOrdersRow> = {
  title: <Trans>Ship To</Trans>,
  dataIndex: 'shipTo',
  key: 'shipTo',
  render: (text: string, record: ViewOrdersRow) =>
    record.overviews[0].shipToPartyName,
};
