import { t } from '@lingui/macro';
import { upperCase } from 'lodash';

/* eslint-disable prettier/prettier */
export const OrderStatusTextTransl = {
  ALL: t({ id: 'orderDetails.status.all', message: 'All' }),
  OPEN: t({ id: 'orderDetails.status.open', message: 'Open' }),
  REJECTED: t({ id: 'orderDetails.status.rejected', message: 'Rejected' }),
  UNDER_PRODUCTION: t({ id: 'orderDetails.status.under_production', message: 'Under Production' }),
  PRODUCED: t({ id: 'orderDetails.status.produced', message: 'Produced' }),
  READY_FOR_SHIPMENT: t({ id: 'orderDetails.status.ready_for_shipment', message: 'Ready for Shipment' }),
  IN_TRANS_WH: t({ id: 'orderDetails.status.in_transit_to_wh', message: 'In Transit to Sappi Warehouse' }),
  SHIPMENT_PLANNED: t({ id: 'orderDetails.status.shipment_planned', message: 'Shipment Planned' }),
  SHIPMENT_PLANNED_TO_SAPPI: t({ id: 'orderDetails.status.shipment_planned_to_sappi', message: 'Shipment Planned to Sappi WH' }),
  PARTIALLY_SHIPPED: t({ id: 'orderDetails.status.partially_shipped', message: 'Partially Shipped' }),
  SHIPPED: t({ id: 'orderDetails.status.shipped', message: 'Shipped' }),
  DELIVERED: t({ id: 'orderDetails.status.delivered', message: 'Delivered' }),
  DELIVERED_COMPLETE: t({ id: 'orderDetails.status.delivered_complete', message: 'Delivered Complete' }),
  PARTIALLY_DELIVERED: t({ id: 'orderDetails.status.partially_delivered', message: 'Partially Delivered' }),
}

// TODO: temporary staying here to allow old status text to be converted to new and then allow translation
// NOTE: some are INACCURATE because it doesn't respect status code + extensions as it is not returned from data side
export function mapOldStatusTextToNew(oldStatusText: string, isRoleNA: boolean): string {
  switch(upperCase(oldStatusText)) {
    case 'OPEN':
      return OrderStatusTextTransl.OPEN;
    case 'REJECTED':
      return OrderStatusTextTransl.REJECTED;
    case 'UNDER PRODUCTION':
      return OrderStatusTextTransl.UNDER_PRODUCTION;
    case 'PRODUCTION FINISHED':
    case 'READY FOR SHIPMENT':
      return isRoleNA ? OrderStatusTextTransl.PRODUCED : OrderStatusTextTransl.READY_FOR_SHIPMENT;
    case 'PRODUCED':
      return OrderStatusTextTransl.PRODUCED;
    case 'ON THE WAY TO SAPPI WAREHOUSE':
    case 'MEANS OF TRANSPORT ARRIVAL TO SAPPI WH':
    case 'LOADING MEANS OF TRANSPORT TO SAPPI WH':
    case 'IN SAPPI WAREHOUSE':
    case 'IN TRANSIT TO SAPPI WAREHOUSE':
      return isRoleNA ? OrderStatusTextTransl.IN_TRANS_WH : OrderStatusTextTransl.READY_FOR_SHIPMENT;
    case 'SHIPPING PLANNED/DISPATCH PLANNED TO YOU':
    case 'DISPATCH PLANNED TO YOU':
    case 'SHIPPING PLANNED':
      return OrderStatusTextTransl.SHIPMENT_PLANNED;
    case 'DISP PLAN TS':
      return isRoleNA ? OrderStatusTextTransl.SHIPMENT_PLANNED_TO_SAPPI : OrderStatusTextTransl.READY_FOR_SHIPMENT;
    case 'PARTLY SHIPPED TO YOU':
    case 'EXPECTED ARRIVAL DATE AT YOUR PREMISES OR PORT OF LOAD':
    case 'MEANS OF TRANSPORT DEPARTS SAPPI LOCATION':
    case 'MEANS OF TRANSPORT ARRIVAL AT SAPPI LOCATION':
    case 'LOADING TRUCK AT SAPPI LOCATION':
    case 'LOADING MEANS OF TRANSPORT AT SAPPI LOCATION':
    case 'PARTIALLY SHIPPED':
      return OrderStatusTextTransl.PARTIALLY_SHIPPED;
    case 'SHIPPED':
    case 'SHIPPED EX SAPPI PLANT':
    case 'DELIVERED TO YOUR PREMISES OR PORT OF LOAD':
      return OrderStatusTextTransl.SHIPPED;
    case 'PARTIALLY DELIVERED':
      return OrderStatusTextTransl.PARTIALLY_DELIVERED;
    case 'DELIVERED':
      return OrderStatusTextTransl.DELIVERED;
    case 'DELIVERED COMPLETE':
      return OrderStatusTextTransl.DELIVERED_COMPLETE;
    default:
      return oldStatusText;
  }
}

export const orderStatusMap = [
  {
    value: 'OPEN',
    label: OrderStatusTextTransl.OPEN,
    category: ['NA', 'EU'],
  },
  {
    value: 'REJECTED',
    label: OrderStatusTextTransl.REJECTED,
    category: ['NA', 'EU'],
  },
  {
    value: 'UNDER PRODUCTION',
    label: OrderStatusTextTransl.UNDER_PRODUCTION,
    category: ['NA', 'EU'],
  },
  {
    value: 'PRODUCED',
    label: OrderStatusTextTransl.PRODUCED,
    category: ['NA'],
  },
  {
    value: 'READY FOR SHIPMENT',
    label: OrderStatusTextTransl.READY_FOR_SHIPMENT,
    category: ['EU'],
  },
  {
    value: 'SHIPMENT PLANNED TO SAPPI WH',
    label: OrderStatusTextTransl.SHIPMENT_PLANNED_TO_SAPPI,
    category: ['NA'],
  },
  {
    value: 'IN TRANSIT TO SAPPI WAREHOUSE',
    label: OrderStatusTextTransl.IN_TRANS_WH,
    category: ['NA'],
  },
  {
    value: 'SHIPMENT PLANNED',
    label: OrderStatusTextTransl.SHIPMENT_PLANNED,
    category: ['NA', 'EU'],
  },
  {
    value: 'PARTIALLY SHIPPED',
    label: OrderStatusTextTransl.PARTIALLY_SHIPPED,
    category: ['NA', 'EU'],
  },
  {
    value: 'SHIPPED',
    label: OrderStatusTextTransl.SHIPPED,
    category: ['NA', 'EU'],
  },
  {
    value: 'PARTIALLY DELIVERED',
    label: OrderStatusTextTransl.PARTIALLY_DELIVERED,
    category: ['NA', 'EU'],
  },
  {
    value: 'DELIVERED COMPLETE',
    label: OrderStatusTextTransl.DELIVERED_COMPLETE,
    category: ['NA', 'EU'],
  },
];

export const orderStatusNAMap = orderStatusMap.filter(s => s.category.includes('NA'));
export const orderStatusEUMap = orderStatusMap.filter(s => s.category.includes('EU'));
/* eslint-enable prettier/prettier */
