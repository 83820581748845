import { t } from '@lingui/macro';
import { find, uniqBy, upperCase } from 'lodash';
import getByLink from '../../../services/Util/getByLink.util';
import { Link } from '../../../types/Link.interface';
import Option from '../../../types/Option.type';
import { orderStatusEUMap, orderStatusNAMap } from '../OrderStatuses';

const ANY_OPTION = {
  label: t({ id: 'search.any', message: 'Any' }),
  value: '',
};

export const getStatusOptions = async (
  statusLink: Link | undefined,
  hasNARole: boolean
): Promise<Option[]> => {
  if (statusLink) {
    const allStatuses = hasNARole ? orderStatusNAMap : orderStatusEUMap;
    const statusesByLink = (
      ((await getByLink(statusLink)) as string[]) || []
    ).map((statusText: string) => {
      return {
        label: statusText,
        value: statusText,
        category: hasNARole ? ['NA'] : ['EU'],
      };
    });
    let options = allStatuses.filter((o) =>
      find(statusesByLink, (s) => {
        return upperCase(s.value) === upperCase(o.value); // use only statuses from results
      })
    );
    options = uniqBy([...options, ...statusesByLink], 'value'); // combine potentially old status texts?

    const resolvedOptions = await Promise.all(options);
    return resolvedOptions;
  }
  return Promise.resolve([]);
};

export const getUnfilteredStatusOptions = (hasNARole: boolean): Option[] => {
  return hasNARole ? orderStatusNAMap : orderStatusEUMap;
};

export const getProductOptions = async (
  productLink: Link | undefined
): Promise<Option[]> => {
  if (productLink) {
    const products = (await getByLink(productLink)) as string[];
    const productOptions = products.map((product) => ({
      value: product,
      label: product,
    }));
    return [ANY_OPTION].concat(productOptions);
  }
  return Promise.resolve([]);
};

export const getShipToOptions = async (
  shipToLink: Link | undefined
): Promise<Option[]> => {
  if (shipToLink) {
    const shipTos = (await getByLink(shipToLink)) as string[][];
    const shipToOptions = shipTos.map((shipTo) => ({
      value: shipTo[0],
      label: shipTo[1],
    }));
    return [ANY_OPTION].concat(shipToOptions);
  }
  return Promise.resolve([]);
};
